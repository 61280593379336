import {consola as l} from "consola/basic";
import fcId from "./../js/fcId";



window._sp_queue = [];
window._sp_ = {
    config: {
        accountId: 1839,
        baseEndpoint: 'https://cdn.privacy-mgmt.com',
        gdpr: {},
        events: {
            onConsentReady: async function (message_type, consentUUID, euconsent, info) {
                // console.log('[event] onConsentReady', arguments);
                // console.log('[event] message_type', message_type);
                // console.log('[event] consentUUID', consentUUID);
                // console.log('[event] euconsent', euconsent);
                console.log('[event] info', info);

                let id = await fcId.get();
                if ((id === null && consentUUID) || (consentUUID !== id)) {
                    await fcId.set(consentUUID);
                    l.info('FC ID set: ', consentUUID)
                } else {
                    l.info('FC ID already set:', id)
                }

                let state = typeof info != 'undefined' && typeof info.applies != 'undefined' ? info.applies : 'undefined';

                l.info('Cmp consent applies:', state);
            },
            onError: function () {
                console.log('[event] onError', arguments);
            },
        }
    }
}

const head = document.getElementsByTagName("head")[0];
const script = document.createElement("script");

script.async = true;
script.src = '//cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js';

head.appendChild(script);
